import { isArray } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";

import useHasMounted from "@/hooks/useHasMounted";

const createDuplicates = (events) => {
  return events.flatMap((event) => {
    const startDate = new Date(event.calendarEntry.date.start);
    const endDate = new Date(
      event.calendarEntry.date.end || event.calendarEntry.date.start,
    );

    // Generate an array of dates between start and end dates
    const dateRange = [];
    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      dateRange.push(new Date(currentDate));
    }

    const { slug, title, calendarEntry, subhead } = event;

    // For each date, create a duplicated event
    return dateRange.map((date) => ({
      slug,
      title,
      subhead,
      calendarEntry,
      sortDate: new Date(date).toISOString(),
    }));
  });
};

const prepareList = (input) => {
  const arr = createDuplicates(input);

  const arrPrepared = arr?.filter(Boolean).map((obj) => {
    const sortPrio = [
      obj?.sortDate?.split("-")?.[0],
      new Intl.DateTimeFormat("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(obj?.sortDate)),
      obj?.title,
    ];
    return {
      ...obj,
      ["sortPrio"]: sortPrio,
    };
  });

  return sortAndGroupEvents(arrPrepared);
};

const sortAndGroupEvents = (arr) => {
  //get property to sort by
  const prio = (target, level) => target?.sortPrio?.[level];

  const arrSorted = arr.sort((a, b) => {
    // First, sort by year
    if (prio(a, 0) !== prio(b, 0)) {
      return prio(a, 0) - prio(b, 0);
    }

    if (prio(a, 1) !== prio(b, 1)) {
      return prio(a, 1)?.localeCompare(prio(b, 1));
    }

    return prio(a, 2)?.localeCompare(prio(b, 2));
  });

  // Grouping the sorted array by year and first letter of the last name
  const groupedItems = arrSorted.reduce((result, item) => {
    const section = prio(item, 0);
    const group = `${prio(item, 1)}`;

    if (!result[section]) {
      result[section] = {};
    }

    if (!result[section][group]) {
      result[section][group] = [];
    }

    result[section][group].push(item);

    return result;
  }, {});

  return groupedItems;
};

const ListItemsEvent = (props) => {
  const { locale } = useRouter();
  const hasMounted = useHasMounted();
  const items = prepareList(props?.items);

  return (
    <>
      {Object.entries(items) // Map over Years
        ?.reverse() // Newest year first
        .map(([keyYear, yearValue]) => {
          const year = Object.entries(yearValue);

          const yearRanges = year
            ?.map(([day, events], i) => {
              // We want to bundle together days that have the same events
              // As an intermediate step, we need to get the first and last item of that range
              // So, we save an item if either
              // Its next sibling has the same set of slugs, but its prev sibling does not -> start of range
              // Its prev sibling has the same set of slugs, but its next sibling does not -> end of range
              // This will remove all in-between events

              const prevDay = i === 0 ? [] : year?.[i - 1],
                nextDay = i === year?.length - 1 ? [] : year?.[i + 1];

              // Get Event Slugs for comparison
              const slugs = events
                  ?.map((event) => event?.slug?.current)
                  ?.filter(Boolean)
                  ?.join(","),
                slugsPrev = prevDay?.[1]
                  ?.map((event) => event?.slug?.current)
                  ?.filter(Boolean)
                  ?.join(","),
                slugsNext = nextDay?.[1]
                  ?.map((event) => event?.slug?.current)
                  ?.filter(Boolean)
                  ?.join(",");

              const isRangeStart = slugs === slugsNext && slugs !== slugsPrev,
                isRangeEnd = slugs === slugsPrev && slugs !== slugsNext;

              if (isRangeStart || isRangeEnd) return [day, events];

              if (slugs !== slugsPrev && slugs !== slugsNext)
                return [day, events];
            })
            ?.filter(Boolean);

          // Now that we have the start and end point of a range, we can merge them together with a new key
          const yearMerged = yearRanges
            ?.map(([day, events], i) => {
              const prevDay = i === 0 ? [] : yearRanges?.[i - 1],
                nextDay =
                  i === yearRanges?.length - 1 ? [] : yearRanges?.[i + 1];

              // Get Event Slugs for comparison
              const slugs = events
                  ?.map((event) => event?.slug?.current)
                  ?.filter(Boolean)
                  ?.join(","),
                slugsPrev = prevDay?.[1]
                  ?.map((event) => event?.slug?.current)
                  ?.filter(Boolean)
                  ?.join(","),
                slugsNext = nextDay?.[1]
                  ?.map((event) => event?.slug?.current)
                  ?.filter(Boolean)
                  ?.join(",");

              const isRangeStart = slugs === slugsNext && slugs !== slugsPrev,
                isRangeEnd = slugs === slugsPrev && slugs !== slugsNext;

              if (isRangeStart) {
                return [[day, yearRanges?.[i + 1]?.[0]], events];
              }

              if (slugs !== slugsPrev && slugs !== slugsNext)
                return [day, events];
            })
            ?.filter(Boolean);

          return (
            <li key={keyYear} className="ListItemsEvent">
              <h2>{keyYear}</h2>

              <ul className="groups">
                {yearMerged?.filter(Boolean).map(([day, v], i) => {
                  return (
                    <li key={i} className="group">
                      <h3>
                        {isArray(day)
                          ? day
                              ?.map((d) =>
                                new Intl.DateTimeFormat("de", {
                                  weekday: "long",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(d)),
                              )
                              ?.reduce((p, c) => [
                                p,
                                <>
                                  – <br />
                                </>,
                                c,
                              ])
                          : new Intl.DateTimeFormat("de", {
                              weekday: "long",
                              month: "long",
                              day: "numeric",
                            }).format(new Date(day))}
                      </h3>
                      <ul className="items">
                        {v.map(({ title, subhead, calendarEntry, slug }, i) => (
                          <li key={i} className="item">
                            <Link
                              href={"/event/" + slug?.current}
                              scroll={false}
                            >
                              <div className="date">
                                {hasMounted &&
                                  [
                                    calendarEntry?.date?.start &&
                                      new Intl.DateTimeFormat("de", {
                                        year:
                                          calendarEntry?.date?.end &&
                                          calendarEntry?.date?.start !==
                                            calendarEntry?.date?.end
                                            ? undefined
                                            : "2-digit",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }).format(
                                        new Date(calendarEntry?.date?.start),
                                      ),
                                    calendarEntry?.date?.end &&
                                      calendarEntry?.date?.start !==
                                        calendarEntry?.date?.end &&
                                      new Intl.DateTimeFormat("de", {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }).format(
                                        new Date(calendarEntry?.date?.end),
                                      ),
                                  ]
                                    ?.filter(Boolean)
                                    .join("–")}{" "}
                                {calendarEntry?.time}
                              </div>
                              <h4>
                                {title}
                                {subhead && (
                                  <>
                                    {" "}
                                    <span>{subhead}</span>
                                  </>
                                )}
                              </h4>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}

      <style jsx global>
        {`
          .ListItemsEvent:not(:last-child) {
            margin-bottom: var(--dist-13);
          }

          .ListItemsEvent:only-child {
            margin-top: 0 !important;
          }

          .ListItemsEvent:only-child h2 {
            display: none;
          }

          .ListItemsEvent .groups .items {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--dist-7) var(--global-gutter);
          }

          @media (max-width: 1023px) {
            .ListItemsEvent .groups .items {
              margin-top: var(--dist-7);
            }
          }

          @media (min-width: 1024px) {
            .ListItemsEvent .groups .items {
              grid-template-columns: 1fr 1fr;
              gap: var(--dist-7) var(--global-gutter);
            }
          }

          .ListItemsEvent h2 {
            font-size: var(--fs-xl);
            line-height: var(--lh-xl);
            letter-spacing: var(--ls-xl);
            margin-bottom: var(--dist-7);
            hyphens: auto;
          }

          @media (min-width: 1024px) {
            .ListItemsEvent h2 {
              margin-left: calc(
                (2 * var(--global-column) + 2 * var(--global-gutter)) * -1
              );
            }
          }

          .ListItemsEvent h3 {
            font-size: var(--fs-lg);
            line-height: var(--lh-lg);
            letter-spacing: var(--ls-lg);
            margin-bottom: var(--dist-7);
            hyphens: auto;
          }

          .ListItemsEvent h4 {
            font-size: var(--fs-md-h);
            line-height: var(--lh-md-h);
            hyphens: auto;
          }

          .ListItemsEvent .date {
            font-size: var(--fs-xs);
            line-height: var(--lh-xs);
            font-family: "ABCROMMono-Regular";
            margin-bottom: var(--dist-1);
          }
        `}
      </style>
    </>
  );
};

export default ListItemsEvent;
