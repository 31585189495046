import Link from "next/link";

import Hyperlink from "../Hyperlink";

const prepareList = (type, input) => {
  const arr = input.flatMap(duplicateObjects);

  const arrPrepared = arr?.filter(Boolean).map((obj) => {
    const sortPrio = [obj?.year, obj?.name?.lastName || obj?.name?.firstName];

    return {
      ...obj,
      ["sortPrio"]: sortPrio,
    };
  });

  return sortAndGroupArtists(arrPrepared);
};

// Function to duplicate objects with multiple years
function duplicateObjects(obj) {
  const years = obj.years;
  if (years && years.length > 1) {
    const duplicatedObjects = years.map((year) => {
      // Create a shallow copy of the original object
      const duplicatedObject = { ...obj, year };
      return (
        (obj?.filterYear.includes(year) || obj?.filterYear?.length === 0) &&
        duplicatedObject
      );
    });

    return duplicatedObjects;
  }

  // If the object has only one year, return it as is
  return (
    (obj?.filterYear?.includes(years?.[0]) ||
      obj?.filterYear?.length === 0) && {
      ...obj,
      year: years?.[0],
    }
  );
}

const sortAndGroupArtists = (arr) => {
  //get property to sort by
  const prio = (target, level) => target?.sortPrio?.[level];

  const arrSorted = arr.sort((a, b) => {
    // First, sort by year
    if (prio(a, 0) !== prio(b, 0)) {
      return prio(a, 0) - prio(b, 0);
    }
    // If year is the same, then sort by the first letter of the last name
    return prio(a, 1)?.localeCompare(prio(b, 1));
  });

  // Grouping the sorted array by year and first letter of the last name
  const groupedItems = arrSorted.reduce((result, item) => {
    const section = prio(item, 0);
    const group = `${prio(item, 1)}`.charAt(0).toUpperCase();

    if (!result[section]) {
      result[section] = {};
    }

    if (!result[section][group]) {
      result[section][group] = [];
    }

    result[section][group].push(item);

    return result;
  }, {});

  return groupedItems;
};

const ListItemsArtist = (props) => {
  const items = prepareList("artist", props?.items);

  return (
    <>
      {Object.entries(items)
        .reverse()
        .map(([key, value]) => (
          <li key={key} className="ListItemsArtist">
            <h2>{key}</h2>

            <ul className="items">
              {Object.entries(value).map(([k, v]) => {
                return v.map(({ name, event }, i) => (
                  <li key={i} className="item">
                    {event?.slug?.current ? (
                      <Link
                        href={`/event/${event?.slug?.current}`}
                        scroll={false}
                      >
                        {[name?.firstName, name?.lastName]?.join(" ")}
                      </Link>
                    ) : (
                      [name?.firstName, name?.lastName]?.join(" ")
                    )}
                  </li>
                ));
              })}
            </ul>
          </li>
        ))}

      <style jsx global>
        {`
          .ListItemsArtist:not(:last-child) {
            margin-bottom: var(--dist-13);
          }

          h1.BlockHeadline + * .ListItemsArtist:first-child h2 {
            margin-top: calc(var(--dist-7) * -1);
          }

          .ListItemsArtist .items {
            font-size: var(--fs-sm);
            line-height: var(--lh-sm);
          }

          .ListItemsArtist h2 {
            font-size: var(--fs-xl);
            line-height: var(--lh-xl);
            letter-spacing: var(--ls-xl);
            margin-bottom: var(--dist-7);
          }

          @media (min-width: 1024px) {
            .ListItemsArtist h2 {
              margin-left: calc(
                (2 * var(--global-column) + 2 * var(--global-gutter)) * -1
              );
            }
          }

          .ListItemsArtist h3 {
            font-size: var(--fs-md-h);
            line-height: var(--lh-md-h);
          }
        `}
      </style>
    </>
  );
};

export default ListItemsArtist;
