/* eslint-disable @next/next/no-img-element */
import Img from "next/image";
import { useNextSanityImage } from "next-sanity-image";
import { memo, useMemo, useState } from "react";

import { getClient } from "@/lib/sanity.client";
import { getProportions } from "@/lib/utils";

export const Image = memo(function ({
  media,
  className,
  ratio: ratioCustom,
  children,
  blur = true,
  priority = false,
  sizes,
  onClick,
  style,
  ...props
}) {
  const client = getClient(undefined);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  const { alt, image } = media || {},
    { asset } = image || {},
    imageProps = useNextSanityImage(client, asset, {
      imageBuilder: (imageUrlBuilder, options) => {
        const result = imageUrlBuilder.quality(80);
        return options.width !== null ? result.width(options.width) : result;
      },
    });

  const memoizedAsset = useMemo(() => {
    if (!media?.image?.asset?.metadata) return null;
    const { width, height, aspectRatio } = asset.metadata?.dimensions,
      ratioNative = width / height,
      orientationNative = ratioNative < 1 ? "portrait" : "landscape",
      { ratio, orientation } = getProportions(
        ratioNative,
        orientationNative,
        ratioCustom,
      );

    return { ratio, orientation };
  }, [asset, ratioCustom, media?.image?.asset?.metadata]);

  const { ratio, orientation } = memoizedAsset || {};

  return !imageProps?.src ? null : (
    <>
      <div
        className={["Image", "Media", className].join(" ")}
        data-ratio={ratio}
        key={asset?._id}
        data-size={props?.["data-size"]}
        data-orientation={orientation}
        onClick={onClick}
        style={{
          ...style,
          "--bg": media?.image?.asset?.metadata?.palette?.dominant?.background,

          "--ratio": ratio,
          ...(props?.["data-size"] === "fullscreen"
            ? { objectFit: "cover", ...attributes }
            : {}),
        }}
        {...props}
      >
        <div className="bg" data-loaded={imageIsLoaded} />
        <div className="mask" data-loaded={imageIsLoaded} />
        {imageProps?.src && (
          <Img
            {...props}
            loader={imageProps?.loader}
            src={imageProps?.src}
            width={imageProps?.width}
            height={imageProps?.height}
            sizes={sizes?.replace("var(--ratio)", ratio) || "100vw"}
            blurDataURL={asset?.metadata?.lqip}
            data-orientation={orientation}
            alt={alt || ""}
            priority={priority}
            draggable="false"
            placeholder="blur"
            onLoad={(event) => {
              const target = event.target;

              // next/image use an 1x1 px git as placeholder. We only want the onLoad event on the actual image
              if (target.src.indexOf("data:image/gif;base64") < 0) {
                setImageIsLoaded(true);
              }
            }}
          />
        )}
      </div>

      <style jsx global>{`
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          user-select: none;
          pointer-events: auto;
          object-fit: var(--media-object-fit, cover);
          z-index: 1;
          display: block;
        }

        .Image > .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          user-select: none;
          pointer-events: none;
          transition: 0.3s opacity 0.5s;
          backdrop-filter: blur(10px);
        }

        .Image > .mask[data-loaded="true"] {
          opacity: 0;
        }

        .Image > .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          user-select: none;
          pointer-events: none;
          transition: 0.5s opacity 0.3s;
          background: var(--bg);
        }

        .Image > .bg[data-loaded="true"] {
          opacity: 0;
        }

        .Image {
          position: relative;
          overflow: hidden;
          background: var(--bg);
          aspect-ratio: var(--ratio);
        }
      `}</style>
    </>
  );
});
