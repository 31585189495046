/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */

import getVideoId from "get-video-id";
import { useRouter } from "next/router";
import { useState } from "react";

import Content from "../content/Content";

const Iframe = ({ url, texts }) => {
  const { loadButtonText, iframeText } = texts || {};

  const { locale } = useRouter();

  const { service, id } = getVideoId(url);
  const isYoutube = id && service === "youtube";
  const isVimeo = id && service === "vimeo";

  const [showOverlay, setShowOverlay] = useState(true);

  const hideOverlay = () => {
    setShowOverlay(false);
  };

  const iframeSrc =
    // If Youtube
    isYoutube
      ? `https://www.youtube-nocookie.com/embed/${id}`
      : // … or if Vimeo
        isVimeo
        ? `http://player.vimeo.com/video/${id}`
        : // Otherwise hide
          null;

  let thumbnail;
  if (isVimeo && id) {
    thumbnail = `https://vumbnail.com/${id}.jpg`;
  }
  if (isYoutube && id) {
    thumbnail = `https://img.youtube.com/vi/${id}/0.jpg`;
  }

  return (
    <>
      <div className="Iframe">
        {!showOverlay && (
          <iframe
            src={iframeSrc}
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          ></iframe>
        )}
        {showOverlay && (
          <div className="IframeOverlay">
            <Content blocks={iframeText?.[locale] || []} />
            <button onClick={hideOverlay}>{loadButtonText?.[locale]}</button>
          </div>
        )}
        <img src={thumbnail} />
      </div>

      <style jsx global>
        {`
          .Iframe button {
            font-size: var(--fs-xs);
            line-height: var(--lh-xs);
            font-family: "ABCROMMono-Regular";
            background: var(--color-secondary-light);
            color: var(--color-primary);
            margin-top: var(--dist-3);
            padding: var(--dist-1);
            border-radius: 2em;
          }

          .Iframe {
            width: 100%;
            position: relative;
            overflow: hidden;
          }
          .Iframe::before {
            display: block;
            content: "";
            padding-top: 56.25%;
          }
          .Iframe iframe,
          .Iframe img,
          .Iframe .IframeOverlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            margin: 0;
            padding: 0;
          }
          .Iframe .IframeOverlay {
            z-index: 3;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: var(--color-primary-overlay);
            color: var(--color-secondary);
            font-size: var(--fs-sm);
            line-height: var(--lh-sm);
          }
          .Iframe .IframeOverlay div {
            max-width: 40ch;
          }
          .IframeOverlay .Hyperlink.Link {
            display: unset;
            text-decoration: none;
          }
          .Iframe iframe {
            z-index: 2;
          }
        `}
      </style>
    </>
  );
};

export default Iframe;
