/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */

import Content from "@/components/content/Content";
import Iframe from "@/components/shared/Iframe";

const BlockMediaEmbed = ({ value }) => {
  const { url, caption, texts } = value || {};

  return value ? (
    <>
      <figure className="Block BlockMediaEmbed">
        <Iframe url={url} texts={texts} />
        {caption && (
          <figcaption className="caption">
            <Content blocks={caption} />
          </figcaption>
        )}
      </figure>

      <style jsx global>{`
        .BlockMediaEmbed {
          padding: 0 var(--dist-1);
        }
        @media (min-width: 1024px) {
          .BlockMediaEmbed {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }
        }
        .BlockMediaEmbed .caption {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        .BlockMediaEmbed .caption .BlockText {
          padding: 0;
          margin-top: var(--dist-1);
        }
      `}</style>
    </>
  ) : null;
};

export default BlockMediaEmbed;
