import Grid from "../Grid";
import ListItemsArtist from "./ListItemsArtist";
import ListItemsEvent from "./ListItemsEvent";

const List = ({ items, type }) => {
  return (
    <>
      {type === "resource" ? (
        <Grid items={items} type="resource" size="small" />
      ) : (
        <ul className="List">
          {type === "artist" && <ListItemsArtist items={items} />}
          {type === "event" && <ListItemsEvent items={items} />}
          {type === "exhibition" && <ListItemsEvent items={items} />}
        </ul>
      )}

      <style jsx global>{`
        .List {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media (min-width: 1024px) {
          .List {
            padding: 0
              calc(
                2 * var(--global-column) + 2 * var(--global-gutter) +
                  var(--dist-1)
              );
          }
        }

        .List ul {
          width: 100%;
        }

        .List .groups li {
          display: block;
        }

        .List .groups > li:not(:last-child) {
          margin-bottom: var(--dist-17);
        }
      `}</style>
    </>
  );
};

export default List;
