import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import Media from "@/components/shared/Media";
import useHasMounted from "@/hooks/useHasMounted";

const CardArchive = ({
  title,
  slug,
  thumbnail,
  events,
  artists,
  from,
  to,
  ...props
}) => {
  const { locale } = useRouter();

  const hasMounted = useHasMounted();

  const { t } = useTranslation("common");

  return (
    <>
      <div className="CardArchive">
        <Link href={`/archive/${slug?.current}`} scroll={false}>
          {/* Title */}
          <div className="CardArchiveTitle">{title}</div>
          <div className="CardArchiveInfo">
            {hasMounted &&
              [
                from &&
                  new Intl.DateTimeFormat("de", {
                    year: to ? undefined : "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(from)),
                to &&
                  new Intl.DateTimeFormat("de", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(to)),
              ]
                ?.filter(Boolean)
                .join("–")}
            <br />
            {t("archive-events", { count: events })},{" "}
            {t("archive-artists", { count: artists })}
          </div>
          <div className="CardArchiveBottom">
            {/* Thumbnail */}
            {thumbnail && (
              <figure className="CardArchiveMedia">
                <Media media={thumbnail} />
              </figure>
            )}
            {/* Date */}
          </div>
        </Link>
      </div>
      <style jsx global>{`
        @media (hover: hover) {
          .CardArchive a {
            transition: opacity 0.2s;
          }

          .CardArchive a:hover {
            opacity: 0.5;
          }
        }

        .CardArchiveBottom {
          display: flex;
          flex-direction: column;
          margin-top: var(--dist-1);
        }
        .CardArchiveTitle {
          grid-area: title;
          font-size: var(--fs-md-h);
          line-height: var(--lh-md-h);
          text-wrap: balance;
        }
        .CardArchiveMedia {
          grid-area: media;
        }

        .CardArchiveInfo {
          grid-area: info;
          margin-top: var(--dist-3);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCRomMono-Regular";
        }

        .CardArchive .CardArchiveBottom {
          margin-top: var(--dist-5);
          flex-direction: column-reverse;
        }
      `}</style>
    </>
  );
};

export default CardArchive;
