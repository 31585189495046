import { isEmpty } from "lodash";
import Link from "next/link";

import Hyperlink from "../shared/Hyperlink";
import BlockGroup from "./blocks/BlockGroup";
import BlockHeadline from "./blocks/BlockHeadline";
import BlockLinks from "./blocks/BlockLinks";
import BlockList from "./blocks/BlockList";
import BlockLogoWall from "./blocks/BlockLogoWall";
import BlockMap from "./blocks/BlockMap";
import BlockMediaBanner from "./blocks/BlockMediaBanner";
import BlockMediaEmbed from "./blocks/BlockMediaEmbed";
import BlockMediaGallery from "./blocks/BlockMediaGallery";
import BlockMediaSingle from "./blocks/BlockMediaSingle";
import BlockText from "./blocks/BlockText";
import BlockTextColumns from "./blocks/BlockTextColumns";

const components = {
  undefined: (props) => {
    return null;
  },
  list: {
    bullet: ({ children, ...props }) => {
      return <BlockList as="ul">{children}</BlockList>;
    },
    number: ({ children }) => <BlockList as="ol">{children}</BlockList>,
  },
  listItem: {
    bullet: ({ children }) => <li>{children}</li>,
  },

  block: {
    h1: ({ children }) => <BlockHeadline as="h1">{children}</BlockHeadline>,
    h2: ({ children }) => <BlockHeadline as="h2">{children}</BlockHeadline>,
    h3: ({ children }) => <BlockHeadline as="h3">{children}</BlockHeadline>,
    h4: ({ children }) => <BlockHeadline as="h4">{children}</BlockHeadline>,

    normal: ({ children }) => <BlockText type="normal">{children}</BlockText>,
    small: ({ children }) => <BlockText type="small">{children}</BlockText>,
    large: ({ children }) => <BlockText type="large">{children}</BlockText>,
  },

  marks: {
    uppercase: (props) => (
      <span style={{ textTransform: "uppercase" }}>{props?.children}</span>
    ),
    highlight: (props) => <span className="highlight">{props?.children}</span>,
    em: ({ children }) => <em>{children}</em>,

    link: ({ value, children }) => {
      const { href, text, type, file, blank, reference, styleButton } = value;

      return (
        <Hyperlink
          className={styleButton ? "Button" : "Link"}
          link={{ href, text, type, file, blank, reference }}
        >
          {children || text}
        </Hyperlink>
      );
    },

    // annotation: (props) => <MarkFootnote {...props} />,
  },

  types: {
    undefined: (props) => {
      return null;
    },

    blockMediaSingle: (props) => <BlockMediaSingle {...props} />,
    blockMediaGallery: (props) => <BlockMediaGallery {...props} />,
    blockLinks: (props) => <BlockLinks {...props} />,
    blockGroup: (props) => <BlockGroup {...props} />,
    blockMap: (props) => <BlockMap {...props} />,
    blockMediaBanner: (props) => <BlockMediaBanner {...props} />,
    blockMediaEmbed: (props) => <BlockMediaEmbed {...props} />,
    blockTextColumns: (props) => <BlockTextColumns {...props} />,
    blockLogoWall: (props) => <BlockLogoWall {...props} />,
  },
};

export default components;
