import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import BlockGroup from "@/components/content/blocks/BlockGroup";
import BlockHeadline from "@/components/content/blocks/BlockHeadline";
import BlockMediaGallery from "@/components/content/blocks/BlockMediaGallery";
import BlockText from "@/components/content/blocks/BlockText";
import Content from "@/components/content/Content";

const PageOutro = ({ page, events }) => {
  const { _type, type, gallery, title, calendarEntry, more } = page || {};
  const hasIntro = !!_type && ["event", "exhibition"].includes(_type);
  const { t } = useTranslation("common");

  const { locale } = useRouter();

  return !hasIntro ? null : (
    <>
      <section className="PageOutro">
        <Content full>
          <BlockText type="small" as="div">
            {calendarEntry?.venue?.address?.[locale]}
            <br />
            <br />
            {t("opening-times")}
            <br />
            {calendarEntry && (
              <ul>{calendarEntry?.venue?.openingHours?.[locale]}</ul>
            )}
            <br />
            {calendarEntry?.venue?.entranceFee?.[locale]}
            <br />
            <br />
            <a href={""}>{t("plan-route")}</a>
            <br />
            <br />
            <a href={calendarEntry?.venue?.website}>
              {calendarEntry?.venue?.website}
            </a>
          </BlockText>
          {more?.length > 0 && (
            <BlockHeadline as="h1">
              {type !== "occasion"
                ? t("more-exhibitions")
                : t("more-occasions")}
            </BlockHeadline>
          )}
          {more?.length > 0 && (
            <BlockGroup
              value={
                _type === "event"
                  ? { events: more, type: "event" }
                  : { exhibitions: more, type: "exhibition" }
              }
            />
          )}
        </Content>
      </section>
      <style jsx global>{`
        .PageOutro {
          margin-top: var(--dist-7);
        }

        .PageOutro h2 {
          font-size: var(--fs-xl);
          line-height: var(--lh-xl);
          letter-spacing: var(--ls-xl);
          padding: 0 var(--dist-1);
          padding-top: calc(var(--dist-7) + var(--dist-5));
        }

        .PageOutro .BlockText {
          padding: 0 var(--dist-1);
        }

        @media (min-width: 1024px) {
          .PageOutro .BlockText {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
          }
        }
      `}</style>
    </>
  );
};

export default PageOutro;
