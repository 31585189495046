import Link from "next/link";
import { useRouter } from "next/router";

import Media from "@/components/shared/Media";
import useHasMounted from "@/hooks/useHasMounted";

const CardEvent = ({
  title,
  subhead,
  slug,
  thumbnail,
  size,
  calendarEntry,
  ...props
}) => {
  const { locale } = useRouter();

  const hasMounted = useHasMounted();

  return (
    <>
      <div className="CardEvent" data-size={size}>
        <Link href={`/event/${slug?.current}`} scroll={false}>
          {/* Title */}
          <div className="CardEventTitle">
            {title}
            <span
              style={{
                display: "inline-block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {subhead}
            </span>
          </div>
          <div className="CardEventBottom">
            {/* Thumbnail */}
            {thumbnail && (
              <figure className="CardEventMedia">
                <Media media={thumbnail} />
              </figure>
            )}
            {/* Date */}
            <div className="CardEventInfo">
              <div className="CardEventInfoDate">
                {hasMounted &&
                  [
                    calendarEntry?.date?.start &&
                      new Intl.DateTimeFormat("de", {
                        year: calendarEntry?.date?.end ? undefined : "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(calendarEntry?.date?.start)),
                    calendarEntry?.date?.end &&
                      new Intl.DateTimeFormat("de", {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(calendarEntry?.date?.end)),
                  ]
                    ?.filter(Boolean)
                    .join("–")}{" "}
                {calendarEntry?.time}
              </div>
              <div className="CardEventInfoVenue">
                {calendarEntry?.venue?.title}
              </div>
            </div>
          </div>
        </Link>
      </div>
      <style jsx global>{`
        @media (hover: hover) {
          .CardEvent a {
            transition: opacity 0.2s;
          }

          .CardEvent a:hover {
            opacity: 0.5;
          }
        }

        .CardEventBottom {
          display: flex;
          flex-direction: column;
          margin-top: var(--dist-1);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCRomMono-Regular";
        }
        .CardEventTitle {
          grid-area: title;
          font-size: var(--fs-md);
          line-height: var(--lh-md-h);
          width: 100%;
          height: calc(var(--lh-md-h) * 2);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          hyphens: auto;
          text-wrap: balance;
        }

        .CardEvent[data-size="large"] .CardEventTitle {
          font-size: var(--fs-lg);
          line-height: var(--lh-lg);
          letter-spacing: var(--ls-lg);
          height: calc(var(--lh-lg) * 2);
          -webkit-line-clamp: 2;
        }

        .CardEventMedia {
          grid-area: media;
        }

        .CardEventMedia .Media {
          width: 100%;
          aspect-ratio: var(--ratio);
        }

        .CardEventInfo {
          grid-area: info;
          margin-top: var(--dist-1-5);
        }
        .CardEvent[data-size="large"] .CardEventBottom {
          margin-top: var(--dist-2);
          flex-direction: column-reverse;
        }
        .CardEvent[data-size="large"] .CardEventInfo {
          margin-top: 0;
          margin-bottom: var(--dist-5);
        }
      `}</style>
    </>
  );
};

export default CardEvent;
