import { isEmpty } from "lodash";

const BlockText = ({ as: P = "p", type, children }) => {
  return isEmpty(children) ? null : (
    <>
      <P className="BlockText" data-type={type}>
        {children}
      </P>

      <style jsx global>{`
        .Content[data-full="true"] > .BlockText {
          padding: 0 var(--dist-1);
        }

        .Content[data-full="true"] > .BlockText + .BlockText {
          padding-top: calc(var(--dist-5));
        }

        .Content[data-full="true"] > .BlockHeadline.h1 + .BlockText {
          padding-top: calc(var(--dist-7));
        }

        .Content[data-full="true"] > .BlockHeadline + .BlockText {
          padding-top: calc(var(--dist-5));
        }

        .Content[data-full="true"]
          > .BlockHeadline
          + .BlockText[data-type="small"] {
          padding-top: calc(var(--dist-3));
        }

        .Content[data-full="true"] > .BlockText[data-type="normal"] {
          font-size: var(--fs-sm);
          line-height: var(--lh-sm);
          font-family: "Eliza-Regular";
        }

        .BlockText[data-type="small"] {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        .BlockText[data-type="large"] {
          font-size: var(--fs-md);
          line-height: var(--lh-md);
        }

        .Content > .BlockText a {
          overflow-wrap: break-word;
        }

        @media (min-width: 1024px) {
          .Content[data-full="true"] > .BlockText[data-type="normal"] {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }

          .Content[data-full="true"] > .BlockText[data-type="small"] {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }

          .Content[data-full="true"] > .BlockText[data-type="large"] {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }
        }
      `}</style>
    </>
  );
};

export default BlockText;
