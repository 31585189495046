import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import Content from "@/components/content/Content";
import Media from "@/components/shared/Media";
import useHasMounted from "@/hooks/useHasMounted";
import { formatBytes } from "@/lib/utils";

import Iframe from "../Iframe";

const CardResource = ({
  size,
  title,
  slug,
  thumbnail,
  description,
  media,
  date,
  file,
  type,
  ...props
}) => {
  const { locale } = useRouter();

  const asset = media?.image?.asset || file?.asset,
    { url, extension } = asset || {};

  return (
    <>
      <div className="CardResource" data-size={size}>
        {type === "embed" ? (
          <div>
            <div className="CardResourceMeta">
              {new Intl.DateTimeFormat("de", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(date))}
              <br />
              {type === "embed"
                ? "EMBED"
                : [extension.toUpperCase(), formatBytes(asset?.size, 2, locale)]
                    ?.filter(Boolean)
                    .join(", ")}
            </div>
            {/* Title */}
            <div className="CardResourceTitle">{title}</div>
            {size !== "list" && (
              <div className="CardResourceInfo">
                <Content blocks={description} />
              </div>
            )}
            {size !== "list" && (
              <div className="CardResourceBottom">
                <Iframe url={props?.url} />
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="CardResourceMeta">
              {new Intl.DateTimeFormat("de", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(date))}
              <br />
              {type === "embed"
                ? "EMBED"
                : [extension.toUpperCase(), formatBytes(asset?.size, 2, locale)]
                    ?.filter(Boolean)
                    .join(", ")}
            </div>
            {/* Title */}
            <div className="CardResourceTitle">{title}</div>
            {size !== "list" && description?.length > 0 && (
              <div className="CardResourceInfo">
                <Content blocks={description} />
              </div>
            )}
            {size !== "list" && type !== "file" && (
              <div className="CardResourceBottom">
                {/* Thumbnail */}
                {(media || thumbnail) && (
                  <figure className="CardResourceMedia">
                    <Media media={media || thumbnail} />
                  </figure>
                )}
                {/* Date */}
              </div>
            )}
            <a href={url + "?dl"}>Download</a>
          </div>
        )}
      </div>
      <style jsx global>{`
        .CardResourceBottom {
          display: flex;
          flex-direction: column;
          margin-top: var(--dist-1);
        }
        .CardResourceTitle {
          grid-area: title;
          font-size: var(--fs-md-h);
          line-height: var(--lh-md-h);
          text-wrap: balance;
        }

        .CardResource a {
          border: 1px solid var(--color-primary);
          border-radius: 99999px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: auto;
          height: var(--dist-3);
          padding: 0 0.75em;
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
          margin-top: var(--dist-3);
        }
        @media (hover: hover) {
          .CardResource a {
            transition:
              background-color 0.2s,
              color 0.2s;
          }
          .CardResource a:hover {
            background: var(--color-primary);
            color: var(--color-secondary);
          }
        }

        .CardResource[data-size="medium"] .CardResourceTitle {
          width: 100%;
          height: calc(var(--lh-md-h) * 2);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .CardResourceMedia {
          grid-area: media;
        }

        .CardResourceMeta {
          margin-bottom: var(--dist-1);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCRomMono-Regular";
          width: 100%;
        }

        .CardResource[data-size="list"] .CardResourceTitle {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCRomMono-Regular";
          width: 100%;
        }

        .CardResourceInfo {
          grid-area: info;
          margin-top: var(--dist-3);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCRomMono-Regular";
          width: 100%;
          height: calc(var(--lh-xs) * 1);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        .CardResource .CardResourceBottom {
          margin-top: var(--dist-3);
          flex-direction: column-reverse;
        }
      `}</style>
    </>
  );
};

export default CardResource;
