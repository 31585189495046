import Link from "next/link";
import { useRouter } from "next/router";

import Media from "@/components/shared/Media";
import useHasMounted from "@/hooks/useHasMounted";

import Hyperlink from "../Hyperlink";

const CardPublication = ({
  title,
  slug,
  thumbnail,
  content,
  info,
  link,
  description,
  ...props
}) => {
  const { locale } = useRouter();

  const hasMounted = useHasMounted();

  return (
    <>
      <div className="CardPublication">
        <Link href={`/publication/${slug?.current}`} scroll={false}>
          {/* Title */}
          <div className="CardPublicationTitle">{title?.[locale]}</div>
          <div className="CardPublicationDescription">
            {description?.[locale]}
          </div>
          <div className="CardPublicationBottom">
            {/* Thumbnail */}
            {thumbnail && (
              <figure className="CardPublicationMedia">
                <Media media={thumbnail} />
              </figure>
            )}
            {/* Date */}
            <div className="CardPublicationContent">{content?.[locale]}</div>
            <div className="CardPublicationInfo">{info?.[locale]}</div>
            <Hyperlink className="CardPublicationBtn" link={link} />
          </div>
        </Link>
      </div>
      <style jsx global>{`
        @media (hover: hover) {
          .CardPublication a {
            transition: filter 0.2s;
          }

          .CardPublication a:hover {
            opacity: 0.5;
          }
        }

        .CardPublicationBottom {
          margin-top: var(--dist-1);
        }
        .CardPublicationTitle {
          grid-area: title;
          font-size: var(--fs-md-h);
          line-height: var(--lh-md-h);
          text-wrap: balance;
        }
        .CardPublicationMedia {
          margin-top: var(--dist-5);
        }

        .CardPublicationInfo {
          grid-area: info;
          margin-top: var(--dist-7);
        }

        .CardPublicationContent {
          white-space: pre-line;
          font-size: var(--fs-sm);
          line-height: var(--lh-sm);
          font-family: "Eliza-Regular";
          margin-top: var(--dist-5);
        }

        .CardPublicationDescription {
          white-space: pre-line;
          margin-top: var(--dist-3);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        .CardPublicationInfo {
          white-space: pre-line;
          margin-top: var(--dist-5);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        .CardPublicationBtn {
          margin-top: var(--dist-3);
          border: 1px solid var(--color-primary);
          border-radius: 99999px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: auto;
          height: var(--dist-3);
          padding: 0 0.75em;
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        @media (min-width: 1024px) {
          .CardPublication {
            padding-left: calc(
              2 * var(--global-gutter) + 2 * var(--global-column)
            );
          }

          .CardPublicationMedia {
            width: calc(3 * var(--global-gutter) + 4 * var(--global-column));
          }

          .CardPublicationContent {
            width: calc(7 * var(--global-gutter) + 8 * var(--global-column));
            column-count: 2;
            column-gap: var(--global-gutter);
          }

          .CardPublicationInfo {
            width: calc(3 * var(--global-gutter) + 4 * var(--global-column));
          }
        }
      `}</style>
    </>
  );
};

export default CardPublication;
