import { isEmpty } from "lodash";

const BlockHeadline = ({ as: H, children }) => {
  return isEmpty(children) ? null : (
    <>
      <H className="BlockHeadline">{children}</H>

      <style jsx global>{`
        .Content[data-full="true"] > .BlockHeadline {
          padding: 0 var(--dist-1);
        }

        .Content[data-full="true"]
          > .BlockHeadline:not(h4)
          + :is([class*=" Block"], [class^="Block"]) {
          padding-top: calc(var(--dist-7));
        }

        .Content[data-full="true"] > .BlockHeadline:not(h4) + .BlockText {
          padding-top: calc(var(--dist-7));
        }

        .Content[data-full="true"] > h4.BlockHeadline + .BlockText {
          padding-top: 0;
        }

        .Content h1.BlockHeadline {
          width: 100%;
          font-size: var(--fs-xl);
          line-height: var(--lh-xl);
          letter-spacing: var(--ls-xl);
          hyphens: auto;
        }

        .Content h1.BlockHeadline + h1.BlockHeadline {
          padding-top: 0 !important;
        }

        .Content h2.BlockHeadline {
          font-size: var(--fs-lg);
          line-height: var(--lh-lg);
          letter-spacing: var(--ls-lg);
        }

        .Content h3.BlockHeadline {
          font-size: var(--fs-md-h);
          line-height: var(--lh-md-h);
        }

        .Content h3.BlockHeadline + p {
          padding-top: var(--dist-3);
        }

        .Content h4.BlockHeadline {
          font-size: var(--fs-xs);
          line-height: var(--lh-sm);
          font-family: "ABCROMMono-Regular";
        }

        @media (min-width: 1024px) {
          .Content[data-full="true"] > .BlockHeadline:is(h2, h3, h4, h5, h6) {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }
        }
      `}</style>
    </>
  );
};

export default BlockHeadline;
