import { motion } from "framer-motion";

import Content from "@/components/content/Content";

import PageIntro from "./PageIntro";
import PageOutro from "./PageOutro";

const Page = ({ page, loading, ...props }) => {
  return (
    <>
      <motion.article
        className="Page"
        initial={{ opacity: 0 }}
        animate={{ opacity: loading ? 0.25 : 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.66, ease: "easeOut" }}
      >
        <PageIntro page={page} />
        <section className="PageContent">
          <Content blocks={page?.content} full />
        </section>
        <PageOutro page={page} />
      </motion.article>
      <style jsx global>{`
        .Page section:first-child {
          margin-top: calc(var(--dist-3) - var(--dist-1));
        }
      `}</style>
    </>
  );
};

export default Page;
