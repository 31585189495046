import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import useMeasure from "react-use-measure";

import Carousel from "@/components/shared/Carousel";
import Media from "@/components/shared/Media";

import Content from "../Content";

const BlockMediaGallery = ({ value }) => {
  const { gallery, size = "default" } = value;

  const [iActive, setI] = useState(0);

  const OPTIONS = {
    containScroll: "trimSnaps",
    skipSnaps: true,
    loop: true,
    align: "start",
  };

  const [ref, bounds] = useMeasure();

  return !gallery ? null : (
    <>
      <motion.section
        className="Block BlockMediaGallery"
        layout="position"
        viewport={{ amount: 0.5 }}
        data-size={size}
        style={{ "--h-caption": bounds?.height + "px" }}
      >
        <Carousel options={OPTIONS} setI={setI} auto>
          {gallery?.map((media, i) => (
            <Media key={i} media={media} />
          ))}
        </Carousel>
        <AnimatePresence mode="wait">
          {!!gallery?.[iActive]?.content && (
            <motion.div
              className="caption"
              ref={ref}
              key={iActive}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.3, duration: 0.3 },
              }}
              exit={{ opacity: 0, transition: { delay: 0 } }}
            >
              <Content blocks={gallery?.[iActive]?.content} />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="space" />
      </motion.section>

      <style jsx global>{`
        .BlockMediaGallery {
          --w-main: calc(100vw - var(--dist-1) * 2);
          grid-column: full;
          position: relative;
        }

        .BlockMediaGallery .embla__container {
          transition: 0.3s height 0.3s cubic-bezier(0.87, 0, 0.13, 1);
          display: flex;
          align-items: flex-start;
        }

        .BlockMediaGallery .space {
          height: var(--h-caption);
          transition: 0.3s height cubic-bezier(0.87, 0, 0.13, 1);
        }

        .BlockMediaGallery .Media {
          width: var(--w-main);
          height: calc(var(--w-main) / var(--ratio));
        }

        .BlockMediaGallery .Content {
          width: auto;
        }

        .BlockMediaGallery .Media {
          --h: min(((var(--w-main)) / var(--ratio)), (100vh / (3 / 2)));
          width: calc(var(--h) * var(--ratio));
          height: var(--h);
        }

        .BlockMediaGallery .caption {
          width: 100%;
          position: absolute;
          top: calc(100% - var(--h-caption));
          z-index: 99;
          padding-top: var(--dist-1);
          padding-right: var(--dist-1);
          padding-left: var(--dist-1);
          min-height: calc(var(--dist-7));
          padding-bottom: calc(var(--dist-1-5));
        }

        .BlockMediaGallery .BlockText {
          font-size: var(--fs-xs) !important;
          line-height: var(--lh-xs) !important;
          font-family: "ABCROMMono-Regular" !important;
          padding: unset var(--dist-1);
        }

        .BlockMediaGallery .embla__viewport,
        .BlockMediaGallery .embla__slide {
          padding: 0 calc(var(--dist-1) / 2);
        }

        @media (min-width: 1024px) {
          .BlockMediaGallery .caption {
            padding-top: var(--dist-1-5);
            padding-left: calc(
              var(--global-column) * 7 + var(--global-gutter) * 7
            );
            padding-right: var(--dist-1);
          }

          .BlockMediaGallery + h1 {
            padding-top: 0px !important;
          }
        }
      `}</style>
    </>
  );
};

export default BlockMediaGallery;
