import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useState } from "react";
import useMeasure from "react-use-measure";

import BannerSlide from "@/components/shared/BannerSlide";
import Carousel from "@/components/shared/Carousel";
import Media from "@/components/shared/Media";

import Content from "../Content";

const BlockMediaBanner = ({ value }) => {
  const {
    type = "media",
    gallery,
    size = "default",
    text,
    media,
    autoplay,
  } = value;
  const [iActive, setI] = useState(0);

  const OPTIONS = {
    containScroll: "trimSnaps",
    skipSnaps: true,
    loop: true,
    align: "start",
  };

  const [ref, bounds] = useMeasure();

  return !gallery && !media ? null : (
    <>
      <motion.section
        className="Block BlockMediaBanner"
        data-type={type}
        layout="position"
        viewport={{ amount: 0.5 }}
        data-size={size}
        style={{ "--h-caption": bounds?.height + "px" }}
      >
        <Content blocks={text} />
        {type === "media" ? (
          <BannerSlide media={media} active={true} />
        ) : (
          <div>
            <Carousel
              options={OPTIONS}
              setI={setI}
              auto={size === "default"}
              autoplay={autoplay && size === "full"}
            >
              {gallery?.map((media, i) => (
                <BannerSlide key={i} media={media} active={iActive === i} />
              ))}
            </Carousel>
          </div>
        )}
      </motion.section>

      <style jsx global>{`
        .BlockMediaBanner {
          --w-main: calc(100vw - var(--dist-1) * 2);
          --col-media: var(--w-main);
          width: 100vw;
          grid-column: full;
          position: relative;
          display: grid;
          grid-template-columns: 1fr;
          gap: var(--dist-5) var(--global-gutter);
        }

        .BlockMediaBanner > .Content {
          font-size: var(--fs-xl);
          line-height: var(--lh-xl);
          letter-spacing: var(--ls-xl);
        }

        .BlockMediaBanner > .Content p + p {
          margin-top: var(--lh-xl);
        }

        .BlockMediaBanner[data-type="gallery"] .embla {
          position: relative;
        }

        .BlockMediaBanner[data-type="gallery"][data-size="default"]
          .embla__container {
          transition: height 0.2s;
          display: flex;
          align-items: flex-start;
        }

        .BlockMediaBanner[data-type="gallery"] .embla__slide {
          margin-right: calc(var(--dist-1));
        }

        .BlockMediaBanner[data-size="full"] .Media {
          --w: min(
            ((var(--col-media) - var(--dist-1))),
            (
              (
                  100 * var(--svh) -
                    (4 * var(--dist-1) + var(--dist-3) + var(--lh-xs)) - var(
                      --h-slide,
                      0px
                    )
                ) * var(--ratio)
            )
          );
          height: calc(
            (
              (
                100 * var(--svh) -
                  (4 * var(--dist-1) + var(--dist-3) + var(--lh-xs)) - var(
                    --h-slide,
                    0px
                  )
              )
            )
          );
          width: var(--w);
        }

        .BlockMediaBanner[data-size="full"] .caption {
          width: min(
            ((var(--col-media) - var(--dist-1))),
            (
              (
                  100 * var(--svh) -
                    (4 * var(--dist-1) + var(--dist-3) + var(--lh-xs)) - var(
                      --h-slide,
                      0px
                    )
                ) * var(--ratio)
            )
          );
        }

        .BlockMediaBanner[data-size="default"] .Media {
          --h: min(
            ((var(--col-media) - var(--dist-1)) / var(--ratio)),
            (
              100 * var(--svh) -
                (4 * var(--dist-1) + var(--dist-3) + var(--lh-xs)) - var(
                  --h-slide,
                  0px
                )
            )
          );
          width: calc(var(--h) * var(--ratio));
          height: var(--h);
        }

        .BlockMediaBanner .caption {
          width: 100%;
          z-index: 99;
          padding-top: var(--dist-1-5);
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
        }

        .BlockMediaBanner[data-type="media"] .caption {
          width: calc(
            4 * var(--global-column) + var(--global-gutter) * 3 + var(--dist-1)
          );
          padding: var(--dist-1) var(--dist-1) 0 0;
          margin-left: auto;
        }

        @media (max-width: 1023px) {
          .BlockMediaBanner > .Content {
            padding: 0 var(--dist-1);
          }

          .BlockMediaBanner[data-type="gallery"] .embla {
            position: relative;
            width: 100vw;
          }

          .BlockMediaBanner[data-type="gallery"] .embla__viewport {
            padding-left: calc(var(--dist-1));
          }

          .BlockMediaBanner[data-type="media"] .Media {
            margin-right: auto;
            margin-left: var(--dist-1);
          }

          .BlockMediaBanner[data-type="media"] .caption {
            width: calc((100% - var(--global-gutter)) / 2);
            margin-right: auto;
            margin-left: unset;
            padding: var(--dist-1) var(--dist-1) 0 var(--dist-1);
          }
        }

        @media (min-width: 1024px) {
          .BlockMediaBanner {
            --col-media: calc(
              6 * var(--global-gutter) + 7 * var(--global-column) +
                var(--dist-1)
            );
            display: grid;
            grid-template-columns:
              calc(
                4 * var(--global-gutter) + 5 * var(--global-column) +
                  var(--dist-1)
              )
              var(--col-media);
            gap: var(--dist-5) var(--global-gutter);
          }

          .BlockMediaBanner > .Content {
            padding: 0 0 0 var(--dist-1);
          }

          .BlockMediaBanner[data-type="media"] .Media {
            margin-left: auto;
            margin-right: var(--dist-1);
          }

          .BlockMediaBanner[data-type="media"] .caption {
            padding-left: 0;
          }
        }
      `}</style>
    </>
  );
};

export default BlockMediaBanner;
