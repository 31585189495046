import { useRouter } from "next/router";

import BlockHeadline from "@/components/content/blocks/BlockHeadline";
import BlockMediaGallery from "@/components/content/blocks/BlockMediaGallery";
import BlockText from "@/components/content/blocks/BlockText";
import useHasMounted from "@/hooks/useHasMounted";

const PageIntro = ({ page }) => {
  const { _type, gallery, title, calendarEntry, category, subhead } =
    page || {};
  const hasIntro = !!_type && ["event", "exhibition"].includes(_type);

  const { locale } = useRouter();
  const hasMounted = useHasMounted();

  return !hasIntro ? null : (
    <>
      <section className="PageIntro">
        <div className="Content">
          <BlockMediaGallery value={{ gallery }} />
          <BlockHeadline as={category === "ray" ? "h1" : "h1"}>
            {title}
            <span
              style={{
                display: "block",
                width: "100%",
                textWrap: "balance",
              }}
            >
              {subhead}
            </span>
          </BlockHeadline>
          <BlockText type="small">
            {hasMounted &&
              [
                calendarEntry?.date?.start &&
                  new Intl.DateTimeFormat("de", {
                    year: calendarEntry?.date?.end ? undefined : "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(calendarEntry?.date?.start)),
                calendarEntry?.date?.end &&
                  new Intl.DateTimeFormat("de", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(calendarEntry?.date?.end)),
              ]
                ?.filter(Boolean)
                .join("–")}{" "}
            {calendarEntry?.time}
            <br />
            {calendarEntry?.venue?.title}
          </BlockText>
        </div>
      </section>
      <style jsx global>{`
        .PageIntro {
          margin-bottom: var(--dist-5);
        }

        .PageIntro :is(h1, h2) {
          font-size: var(--fs-xl);
          line-height: var(--lh-xl);
          letter-spacing: var(--ls-xl);
          padding: 0 var(--dist-1);
          padding-top: var(--dist-7);
        }

        .PageIntro h1 {
          font-size: var(--fs-xl);
          line-height: var(--lh-xl);
          letter-spacing: var(--ls-xl);
        }

        .PageIntro h2 {
          font-size: var(--fs-lg);
          line-height: var(--lh-lg);
          letter-spacing: var(--ls-lg);
        }

        .PageIntro > .Content > .BlockText {
          padding: 0 var(--dist-1);
          padding-top: var(--dist-3);
        }

        @media (min-width: 1024px) {
          .PageIntro :is(h1, h2) {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
          }

          .PageIntro :is(h2) {
            padding-right: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
          }

          .PageIntro > .Content > .BlockText {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
          }
        }
      `}</style>
    </>
  );
};

export default PageIntro;
