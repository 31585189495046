import Media from "@/components/shared/Media";

const BlockLogoWall = ({ value }) => {
  const { logos } = value || [];

  return logos?.length ? (
    <>
      <div className="Block BlockLogoWall">
        {logos?.map((logo, i) => {
          if (logo?.url) {
            return (
              <a href={logo.url} target="_blank" key={i}>
                <Media media={logo} />
              </a>
            );
          } else {
            return <Media key={i} media={logo} />;
          }
        })}
      </div>

      <style jsx global>{`
        .BlockLogoWall {
          padding: var(--dist-1) var(--dist-1) 0 var(--dist-1);
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: var(--size-14);
          row-gap: var(--size-4);
        }
        .BlockLogoWall .Media {
          height: var(--size-16);
          width: auto;
        }
        @media (min-width: 1024px) {
          .BlockLogoWall {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }
          .BlockLogoWall .Media {
            height: var(--size-24);
            column-gap: var(--size-20);
          }
        }
      `}</style>
    </>
  ) : null;
};

export default BlockLogoWall;
