import Grid from "@/components/shared/Grid";
import List from "@/components/shared/lists/List";

const BlockGroup = ({ value, children }) => {
  const {
    _key = "",
    size = "medium",
    type = "",
    mode = "manual",
    events = [],
    exhibitions = [],
    artists = [],
    resources = [],
  } = value || {};

  return (
    <>
      <div className="Block BlockGroup" data-size={size} data-type={type}>
        {children ||
          (size === "list" ? (
            <List items={value?.[type + "s"]} type={type} />
          ) : (
            <Grid items={value?.[type + "s"]} type={type} size={size} />
          ))}
      </div>
      <style jsx global>{`
        .BlockGroup {
          padding-left: var(--dist-1);
          padding-right: var(--dist-1);
        }
      `}</style>
    </>
  );
};

export default BlockGroup;
