import Hyperlink from "@/components/shared/Hyperlink";

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
    <path
      fill="var(--color-primary)"
      fillRule="evenodd"
      d="M20 1C9.507 1 1 9.507 1 20c0 10.494 8.507 19 19 19 10.494 0 19-8.506 19-19C39 9.507 30.494 1 20 1ZM0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z"
      clip-rule="evenodd"
    />
    <path
      fill="var(--color-primary)"
      fillRule="evenodd"
      d="m23.145 10.653 8.55 8.847-8.55 8.848-.719-.695 7.396-7.652H8v-1h21.822l-7.396-7.653.72-.695Z"
      clip-rule="evenodd"
    />
  </svg>
);

const BlockLinks = ({ value, children }) => {
  const { links } = value || {};

  return !links || links?.length < 1 ? null : (
    <>
      <ul className="BlockLinks">
        {links?.map((link, i) => (
          <li key={i}>
            <Hyperlink link={link} Icon={Icon} />
          </li>
        ))}
      </ul>

      <style jsx global>{`
        .BlockLinks {
          padding: 0 var(--dist-1);
          font-size: var(--fs-md-h);
          line-height: var(--lh-md-h);
        }

        .BlockLinks li {
          border: 1px solid var(--color-primary);
          display: flex;
        }

        .BlockLinks li a {
          width: 100%;
          padding: var(--dist-1);
          display: inline-flex;
        }

        .BlockLinks li:not(:last-child) {
          margin-bottom: var(--dist-1-5);
        }
      `}</style>
    </>
  );
};

export default BlockLinks;
