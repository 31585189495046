import Content from "@/components/content/Content";
import Media from "@/components/shared/Media";

const BlockMediaSingle = ({ value }) => {
  const { media, size } = value || {},
    { content } = media || {};

  return !media ? null : (
    <>
      <figure className="Block BlockMediaSingle" data-size={size}>
        <Media media={media} />

        {content && (
          <figcaption className="caption">
            <Content blocks={content} />
          </figcaption>
        )}
      </figure>

      <style jsx global>{`
        .BlockMediaSingle {
          position: relative;
          width: 100%;
          padding: 0 var(--dist-1);
        }

        .BlockMediaSingle .caption {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCROMMono-Regular";
          margin-top: var(--dist-1-5);
        }

        @media (min-width: 1024px) {
          .BlockMediaSingle:not([data-size="full-width"]) {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
          }

          .BlockMediaSingle[data-size="medium"] {
            padding-right: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
          }
        }
      `}</style>
    </>
  );
};

export default BlockMediaSingle;
