import CardArchive from "./cards/CardArchive";
import CardArtist from "./cards/CardArtist";
import CardEvent from "./cards/CardEvent";
import CardPublication from "./cards/CardPublication";
import CardResource from "./cards/CardResource";

const Grid = ({ items, type, size }) => {
  const Card =
    type === "artist"
      ? CardArtist
      : type === "archive"
        ? CardArchive
        : type === "publication"
          ? CardPublication
          : type === "resource"
            ? CardResource
            : CardEvent;

  return (
    <>
      <ul
        className="Grid"
        data-size={size}
        data-type={type === "exhibition" ? "event" : type}
      >
        {items?.length &&
          items?.map((item, i) => (
            <Card key={`${type}-${i}`} size={size} {...item} />
          ))}
      </ul>

      <style jsx global>{`
        .Grid {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);

          display: grid !important;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: var(--global-gutter);
          grid-row-gap: var(--dist-7);
        }

        @media (min-width: 1024px) {
          .Grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }
        }

        .Grid[data-size="small"] {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .Grid[data-size="medium"] {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .Grid[data-size="large"] {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media (min-width: 640px) {
          .Grid[data-size="small"] {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          .Grid[data-size="medium"] {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          .Grid[data-size="large"] {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }

        @media (min-width: 768px) {
          .Grid[data-size="small"] {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }

          .Grid[data-size="medium"] {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          .Grid[data-size="large"] {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }

        @media (min-width: 1024px) {
          .Grid[data-size="small"] {
            grid-template-columns: repeat(4, minmax(0, 1fr));
          }

          .Grid[data-size="medium"] {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }

          .Grid[data-size="large"] {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }

        .Grid[data-type="publication"] {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          grid-column-gap: var(--global-gutter);
          grid-row-gap: var(--dist-13);
        }

        .Grid[data-type="resource"]:not([data-size="medium"]) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media (min-width: 1024px) {
          .Grid[data-type="resource"]:not([data-size="medium"]) {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter)
            );
          }
        }

        .Grid[data-type="archive"] {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media (min-width: 480px) {
          .Grid[data-type="archive"] {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
      `}</style>
    </>
  );
};

export default Grid;
