import { Image } from "./Image";
import Video from "./Video";

const Media = (props) => {
  const { media } = props;

  if (!media) return null;

  const { type } = media;
  /*eslint-disable */
  return type === "video" ? <Video {...props} /> : <Image {...props} />;
  /*eslint-enable */
};

export default Media;
