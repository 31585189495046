import { Slot } from "@radix-ui/react-slot";
import { useLiveQuery } from "next-sanity/preview";

export function LiveQueryData({ children, query, params = {}, ...props }) {
  const [page, loading] = useLiveQuery({ page: props?.page }, query, params);

  const previewProps = { ...props?.initial, page };

  if (loading) return "Loading";
  return <Slot {...previewProps}>{children}</Slot>;
}

// Component just renders its children if preview mode is not enabled
export default function LiveQueryWrapper({
  // Is live query mode active?
  isEnabled = false,
  // If so, listen to this query
  query = null,
  // With these params
  params = {},
  // Separate remaining props to pass to the child
  initial,
  children,
  ...props
}) {
  // Render child, with the wrapper's initial data and props
  if (!isEnabled || !query) {
    const nonPreviewProps = { ...initial };

    return <Slot {...nonPreviewProps}>{children}</Slot>;
  }
  // Swap initialData for live data
  return (
    <LiveQueryData initial={initial} query={query} params={params}>
      {children}
    </LiveQueryData>
  );
}
