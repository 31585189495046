import "mapbox-gl/dist/mapbox-gl.css";

import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useRef, useState } from "react";
import Mapbox, { Marker, Popup } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";

import Pin from "../../shared/Pin";

const Map = ({ value }) => {
  const { locale } = useRouter();
  const { venues } = value || [];
  const { layout } = value || "map-only";
  const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
  const mapStyle =
    process.env.NEXT_PUBLIC_MAP_STYLE || "mapbox://styles/mapbox/streets-v12";
  const [selectedVenue, setSelectedVenue] = useState(null);
  const mapRef = useRef(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (
      !selectedVenue?.location?.lat ||
      !selectedVenue?.location?.lng ||
      !mapRef?.current
    ) {
      return;
    }
    mapRef.current.flyTo({
      center: [selectedVenue?.location?.lng, selectedVenue?.location?.lat],
      essential: true,
      zoom: 16,
    });
  }, [selectedVenue]);
  function cleanUrl(url) {
    return url.replace(/^https?:\/\/(?:www\.)?|(\/)+$/g, "");
  }
  const pins = venues?.map((venue, index) => {
    if (!venue?.location?.lat || !venue?.location?.lng) {
      return;
    }
    return (
      <Marker
        key={`marker-${index}`}
        longitude={venue.location.lng}
        latitude={venue.location.lat}
        anchor="bottom"
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setSelectedVenue(venue?._id !== selectedVenue?._id ? venue : null);
        }}
      >
        <Pin active={venue?._id === selectedVenue?._id} index={index + 1} />
      </Marker>
    );
  });
  // Fit bounds to markers / pins
  const coordinates = pins
    ?.map((pin) => {
      if (pin?.props?.longitude && pin?.props?.latitude)
        return [pin?.props?.longitude, pin?.props?.latitude];
    })
    ?.filter(Boolean);

  const applyToArray = (func, array) => func.apply(Math, array);
  const getBounds = (markers) => {
    const lat = markers.map((marker) => marker?.[1] ?? 0);
    const lng = markers.map((marker) => marker?.[0] ?? 0);

    const cornersLngLat = [
      [applyToArray(Math.min, lng), applyToArray(Math.min, lat)],
      [applyToArray(Math.max, lng), applyToArray(Math.max, lat)],
    ];

    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(cornersLngLat, { padding: 5 });

    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  const bounds = getBounds(coordinates);

  const onMapLoad = () => {
    mapRef?.current?.scrollZoom?.disable();
  };

  return (
    <>
      <div className="Block BlockMap" data-layout={layout}>
        {/* Top Area: Map + Table/List */}
        <div className="wrapper">
          {layout !== "text-only" && (
            <Mapbox
              ref={mapRef}
              mapboxAccessToken={mapboxToken}
              cooperativeGestures={true}
              mapStyle={mapStyle}
              initialViewState={{
                latitude: bounds.latitude,
                longitude: bounds.longitude,
                zoom: bounds.zoom,
              }}
              onLoad={onMapLoad}
              maxZoom={20}
            >
              {pins}
              {selectedVenue && (
                <Popup
                  anchor="top-left"
                  offset={[30, 10]}
                  maxWidth="var(--size-80)"
                  longitude={Number(selectedVenue.location.lng)}
                  latitude={Number(selectedVenue.location.lat)}
                  onClose={() => setSelectedVenue(null)}
                  className="Popup"
                >
                  <div className="PopupCol PopupInner">
                    {selectedVenue?.address?.de}
                  </div>
                  <div className="PopupRow">
                    {/* Opening Hours */}
                    <div className="PopupCol PopupInner">
                      {selectedVenue?.openingHours?.[locale] && (
                        <div className="OpeningHour">
                          {selectedVenue?.openingHours?.[locale]}
                        </div>
                      )}
                    </div>
                    <div className="PopupCol">
                      {/* Entrance Fee */}
                      {selectedVenue?.entranceFee?.[locale] && (
                        <div className="PopupCol">
                          <div className="PopupInner">
                            {selectedVenue?.entranceFee?.[locale]}
                          </div>
                        </div>
                      )}
                      {/* Route Link */}
                      <div className="PopupCol">
                        <div className="PopupInner">
                          <a
                            href={`https://www.google.com/maps/dir//${encodeURIComponent(
                              selectedVenue?.address?.[locale] || "",
                            )}/`}
                            target="_blank"
                          >
                            <div>
                              {t("plan-route")}
                              <br />
                              &rarr;
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Website */}
                  {selectedVenue.website && (
                    <div className="PopupCol PopupInner">
                      <a href={selectedVenue.website} target="_blank">
                        {cleanUrl(selectedVenue.website)}
                      </a>
                    </div>
                  )}
                  {/* Free text */}
                  {selectedVenue?.text?.[locale] && (
                    <div className="PopupCol PopupInner">
                      {selectedVenue?.text?.[locale]}
                    </div>
                  )}
                </Popup>
              )}
            </Mapbox>
          )}
          {layout !== "map-only" && (
            <div className="table">
              {venues?.length &&
                venues.map((venue, index) => (
                  <div
                    key={`aside-${index}`}
                    className={`table-folder ${
                      venue?._id === selectedVenue?._id ? "active" : ""
                    }`}
                    onClick={() => {
                      if (layout === "text-only") {
                        return;
                      }
                      setSelectedVenue(
                        venue?._id !== selectedVenue?._id ? venue : null,
                      );
                    }}
                  >
                    <Pin
                      active={venue?._id === selectedVenue?._id}
                      index={index + 1}
                    />
                    <div>{venue?.title}</div>
                    {layout !== "map-table" && (
                      <div className="table-folder-infos">
                        {/* Adress */}
                        {venue?.address?.[locale] && (
                          <p className="table-folder-infos-item">
                            {venue?.address?.[locale]}
                          </p>
                        )}
                        {/* Entrance Fee */}
                        {venue?.entranceFee?.[locale] && (
                          <p className="table-folder-infos-item">
                            {/* {t("entrance-fee")}:<br /> */}
                            {venue?.entranceFee?.[locale]}
                          </p>
                        )}
                        {/* Opening Hours */}
                        {venue?.openingHours?.[locale] && (
                          <p className="table-folder-infos-item">
                            {/* {t("opening-times")}:<br /> */}
                            {venue?.openingHours?.[locale]}
                          </p>
                        )}

                        {/* Website */}
                        {venue?.website && (
                          <p className="table-folder-infos-item">
                            {/* {t("further-info")}:<br /> */}
                            <a href={venue?.website} target="_blank">
                              {cleanUrl(venue?.website)}
                            </a>
                          </p>
                        )}
                        {/* Free Text */}
                        {venue?.text?.[locale] && (
                          <p className="table-folder-infos-item">
                            {venue?.text?.[locale]}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <style jsx global>{`
        .BlockMap {
          width: 100%;
          user-select: none;
          padding: var(--dist-1);
          position: relative;
        }

        .BlockMap > .wrapper {
          display: contents;
        }

        .BlockMap:not([data-layout="text-only"]) > .wrapper::after {
          position: absolute;
          top: var(--dist-7);
          left: var(--dist-1);
          content: "";
          width: calc(100% - 2 * var(--dist-1));
          height: calc(100% - var(--dist-7) - var(--dist-1));
          outline: 1px solid black;
          outline-offset: -1px;
          pointer-events: none;
        }

        .BlockMap:not([data-layout="text-only"]):first-child > .wrapper::after {
          top: var(--dist-1);

          height: calc(100% - var(--dist-1) - var(--dist-1));
        }

        /* map-only & text-only & map-text padding on large screens  */
        @media (min-width: 1024px) {
          .BlockMap[data-layout="map-only"],
          .BlockMap[data-layout="text-only"],
          .BlockMap[data-layout="map-text"] {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }
        }

        .BlockMap[data-layout="text-only"] .Pin {
          pointer-events: none;
        }

        /* MapBox Overwrites (Aspect-Ratio)  */
        .mapboxgl-map {
          border: 1px solid var(--color-primary);
          aspect-ratio: 4/5;
          height: 80vh;
        }

        @media (min-width: 768px) {
          .mapboxgl-map {
            aspect-ratio: 16 / 9;
          }
        }

        @media (min-width: 1024px) {
          .mapboxgl-map {
            aspect-ratio: unset;
          }
        }

        .BlockMap[data-layout="map-only"],
        .BlockMap[data-layout="map-table"] {
        }

        /* map-table Styles */
        @media (min-width: 1024px) {
          .BlockMap[data-layout="map-table"] {
            display: grid;
            grid-template-columns: 2fr 1fr;
          }
        }

        .BlockMap[data-layout="map-table"] .table {
          border: 1px solid var(--color-primary);
          border-top: 0;
          border-bottom: 0;
        }

        .BlockMap[data-layout="map-table"] .table {
          @media (min-width: 1024px) {
            border-top: 1px solid var(--color-primary);
            border-bottom: 1px solid var(--color-primary);
            border-left: 0;
            max-height: 60vh;
            overflow: auto;
          }
        }

        .BlockMap[data-layout="map-table"] .table-folder {
          padding: var(--global-gutter);
          gap: var(--global-gutter);
          display: flex;
          align-items: baseline;
          border-bottom: 1px solid var(--color-primary);
          cursor: pointer;
          transition:
            color 0.33s,
            background 0.33s;
        }

        @media (hover: hover) {
          .BlockMap[data-layout="map-table"] .table-folder:hover {
            background-color: black;
            color: var(--color-secondary-light);
          }

          .BlockMap[data-layout="map-table"] .table-folder:hover .Pin {
            background-color: var(--color-secondary-light);
            color: black;
          }
        }

        .BlockMap[data-layout="map-table"] .table-folder.active {
          background-color: black;
          color: var(--color-secondary-light);
        }

        /* map-text & text-only Styles */
        .BlockMap[data-layout="map-text"],
        .BlockMap[data-layout="text-only"] .table {
          margin-top: var(--size-12);
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-gap: var(--global-gutter);
          grid-row-gap: var(--size-12);
        }

        .BlockMap[data-layout="map-text"],
        .BlockMap[data-layout="text-only"] .table .Pin {
          margin-bottom: var(--dist-2);
        }

        .BlockMap[data-layout="map-text"],
        .BlockMap[data-layout="text-only"] .table-folder {
          font-family: "ABCROMMono-Regular", monospace;
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
        }

        .BlockMap[data-layout="map-text"],
        .BlockMap[data-layout="text-only"] .table-folder-infos {
          display: flex;
          flex-direction: column;
          gap: 1em;
          word-break: break-word;
          white-space: pre-wrap;
        }

        /* Pop Up Styles */
        .mapboxgl-popup-tip,
        .mapboxgl-popup-close-button {
          display: none;
        }

        .mapboxgl-popup-content {
          background-color: black;
          color: var(--color-secondary-light);
          border-radius: 0;
          box-shadow: none;
          width: var(--size-80);
          box-sizing: border-box;
          padding: 0;
        }

        .Popup {
          white-space: pre-wrap;
          font-family: "ABCROMMono-Regular", monospace;
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          border: 1px solid var(--color-secondary-light);
        }

        .PopupRow {
          border-top: 1px solid var(--color-secondary-light);
          display: flex;
        }

        .PopupCol {
          box-sizing: border-box;
          border-top: 1px solid var(--color-secondary-light);
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .PopupCol + .PopupCol,
        .PopupCol:first-child {
          border-top: 0;
        }

        .PopupCol > .PopupCol {
          border-left: 1px solid var(--color-secondary-light);
        }

        .PopupCol > .PopupCol:first-child {
          border-bottom: 1px solid var(--color-secondary-light);
        }

        .PopupCol > .PopupCol {
          border-right: 0;
        }

        .PopupInner {
          padding: var(--size-2);
        }

        .PopupInner a {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .BlockMap .table a {
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 1.5px;
        }
        @media (hover: hover) {
          .BlockMap .table a:hover {
            transition: opacity 0.2s;
            text-decoration-color: var(--color-primary);
            opacity: 0.5;
          }
        }
      `}</style>
    </>
  );
};
export default Map;
