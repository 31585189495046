const Pin = ({ index, active }) => {
  return (
    <>
      <div className={`Pin ${active ? "active" : ""}`}>
        <div className="PinInner">{index}</div>
      </div>
      <style jsx global>{`
        .Pin {
          font-family: "ABCROMMono-Regular", monospace;
          font-size: var(--fs-sm);
          line-height: 1;
          width: var(--size-10);
          height: var(--size-10);
          border-radius: 100%;
          background-color: var(--color-primary);
          color: var(--color-secondary-light);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--color-primary);
          transition:
            color 0.33s,
            background-color 0.33s;
          flex-shrink: 0;
        }
        @media (hover: hover) {
          .Pin:hover {
            color: var(--color-primary);
            background-color: var(--color-secondary-light);
          }
        }
        .Pin.active {
          color: var(--color-primary);
          background-color: var(--color-secondary-light);
        }
      `}</style>
    </>
  );
};
export default Pin;
