import AutoHeight from "embla-carousel-auto-height";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";

import Button from "./Button";

const Carousel = (props) => {
  const { options, children, setI, autoplay } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(
    options,
    [
      props?.auto ? AutoHeight() : null,
      autoplay ? Autoplay({ playOnInit: true, delay: 2000 }) : null,
    ]?.filter(Boolean),
  );

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );
  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback(
    (emblaApi) => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setI(emblaApi.selectedScrollSnap());
      setPrevBtnDisabled(!emblaApi.canScrollPrev());
      setNextBtnDisabled(!emblaApi.canScrollNext());
    },
    [setI],
  );

  useEffect(() => {
    if (!emblaApi) return;
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <>
      <div className="embla">
        <div
          className="embla__viewport"
          ref={emblaRef}
          onClick={({ target }) => {
            if (!target?.closest(".Video")) scrollNext();
          }}
        >
          <div className="embla__container">
            {children.map((child, index) => (
              <div
                className="embla__slide"
                key={index}
                data-active={index === selectedIndex}
              >
                {child}
              </div>
            ))}
          </div>
        </div>

        <div className="embla__buttons">
          <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
          <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
        </div>

        {/* <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => scrollTo(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : '',
              )}
            />
          ))}
        </div> */}
      </div>

      <style jsx global>{`
        .embla {
          --slide-spacing: 0;
          --slide-size: auto;
          --slide-height: auto;
          cursor: grab;
        }

        .embla {
          overflow: hidden;
          position: relative;
        }
        .embla__container {
          display: flex;
        }
        .embla__slide {
          flex: 0 0 100%;
          min-width: 0;
        }

        .embla:active {
          cursor: grabbing;
        }

        .embla__viewport {
          overflow: hidden;
        }
        .embla__container {
          backface-visibility: hidden;
          display: flex;
          touch-action: pan-y;
        }
        .embla__slide {
          flex: 0 0 var(--slide-size);
          min-width: 0;
          padding-left: var(--slide-spacing);
          position: relative;
        }
        .embla__slide__img {
          display: block;
          height: var(--slide-height);
          width: 100%;
          object-fit: cover;
        }

        .embla__dots {
          position: absolute;
          left: 0;
          top: 1em;
          width: 100%;
          display: flex;
          justify-content: center;
          pointer-events: none;
          gap: 1em;
        }

        .embla__dots > button {
          pointer-events: auto;
          width: 1em;
          height: 1em;
          background: rgba(0, 0, 0, 0.1);
          color: var(--color-secondary);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 99999px;
          transition: background 0.33s;
        }

        .embla__dots > button.embla__dot--selected {
          background: var(--color-secondary);
        }

        .embla__buttons {
          position: absolute;
          left: 0;
          top: calc(50% - (var(--dist-3) / 2 + var(--lh-sm) / 4 / 2));
          width: 100%;
          display: flex;
          justify-content: space-between;
          pointer-events: none;
          padding: 0 var(--dist-1);
        }

        .embla__buttons > button {
          pointer-events: all;
          background: unset !important;
        }

        .embla__buttons > button:first-child {
          transform: scaleX(-1);
        }

        .embla__buttons > button svg {
          width: auto;
          height: var(--dist-3);
        }

        .embla__buttons > button:disabled {
          opacity: 0 !important;
        }
      `}</style>
    </>
  );
};

export const DotButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};

export const PrevButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <Button type="button" {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 32">
        <path stroke="#000" d="m20 31 15-15m0 0L20 1m15 15H0" />
      </svg>
    </Button>
  );
};

export const NextButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <Button type="button" {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 32">
        <path stroke="#000" d="m20 31 15-15m0 0L20 1m15 15H0" />
      </svg>
    </Button>
  );
};

export default Carousel;
