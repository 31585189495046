import { PortableText } from "@portabletext/react";

import components from "./components";

const Content = ({ blocks, full, children }) => {
  if (!children && (!blocks || blocks.length === 0)) return null;

  return (
    <>
      <div className="Content" data-full={full}>
        <PortableText
          value={blocks?.filter((block) => {
            return (
              block.children?.length > 1 ||
              block.children?.[0]._type !== "span" ||
              block.children?.[0].text?.trim() !== ""
            );
          })}
          components={{ ...components }}
        />
        {children}
      </div>

      <style jsx global>{`
        .Content {
          width: 100%;
          position: relative;
        }

        .Content > :is([class*=" Block"], [class^="Block"]):empty {
          display: none !important;
        }

        .Content .highlight {
          background: var(--color-yellow);
          box-decoration-break: clone;
          padding: 0 0.25ch;
          margin: 0 -0.25ch;
        }

        .Content > :is([class*=" BlockHeadline"], [class^="BlockHeadline"]),
        .Content > :is([class*=" BlockText"], [class^="BlockText"]) {
          display: block;
        }

        .Content
          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
          + :is([class*=" Block"], [class^="Block"]):not(:is(.BlockText)) {
          padding-top: calc(var(--dist-3));
        }

        .Content
          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
          + :is(.BlockText) {
          padding-top: calc(var(--dist-3));
        }

        .Content
          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
          + :is(.BlockHeadline) {
          padding-top: calc(var(--dist-3));
        }

        .Content[data-full="true"]
          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
          + :is([class*=" Block"], [class^="Block"]):not(:is(.BlockText)) {
          padding-top: calc(var(--dist-5) + var(--dist-7));
        }

        .Content[data-full="true"]
          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
          + :is(.BlockText) {
          padding-top: calc(var(--dist-5) + var(--dist-7));
        }

        .Content[data-full="true"]
          > :is([class*=" Block"], [class^="Block"]):not(:is(.BlockHeadline))
          + :is(.BlockHeadline) {
          padding-top: calc(var(--dist-5) + var(--dist-7));
        }

        .Content[data-full="true"]
          > :is([class*=" Block"], [class^="Block"]).BlockText
          + :is(.BlockText) {
          padding-top: calc(var(--lh-md));
        }

        .Content[data-full="true"]
          > :is([class*=" Block"], [class^="Block"]).BlockText
          + :is(h4.BlockHeadline) {
          padding-top: calc(var(--lh-md)) !important;
        }
      `}</style>
    </>
  );
};

export default Content;
