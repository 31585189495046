import Link from "next/link";
import { useRouter } from "next/router";

import Media from "@/components/shared/Media";
import ConditionalWrapper from "@/components/utils/ConditionalWrapper";

import Hyperlink from "../Hyperlink";

const CardArtist = ({
  thumbnail,
  triennaleYears,
  name,
  yob,
  cob,
  size,
  event,
}) => {
  const { locale } = useRouter();

  return (
    <>
      <div
        className="CardArtist"
        data-size={size}
        data-subgroup={name?.lastName?.[0]?.toLowerCase()}
      >
        <ConditionalWrapper
          condition={!!event?.slug?.current}
          wrapper={(children) => (
            <Link href={`/event/${event?.slug?.current}`} scroll={false}>
              {children}
            </Link>
          )}
        >
          {thumbnail && (
            <figure className="CardArtistMedia">
              <Media media={thumbnail} />
            </figure>
          )}
          <div className="CardArtistName">
            {name?.firstName} {name?.lastName}
          </div>
          {size !== "list" && (
            <div className="CardArtistInfo">
              {/* Birthday */}
              {yob || cob ? (
                <div>
                  ({yob && <span>*{yob}&nbsp;</span>}
                  {cob && <span>{cob}</span>})
                </div>
              ) : null}
              {triennaleYears
                ?.map(({ title }) => title?.[locale]?.toUpperCase())
                ?.join(", ")}
              {event?.title && <br />}
              {event?.title}
            </div>
          )}
        </ConditionalWrapper>
      </div>
      <style jsx global>{`
        .CardArtistMedia {
          margin-bottom: var(--dist-1-5);
          width: 100%;
        }

        @media (hover: hover) {
          .CardArtist a {
            transition: opacity 0.2s;
          }

          .CardArtist a:hover {
            opacity: 0.5;
          }
        }

        .CardArtist:not([data-size="list"]) .CardArtistName {
          font-size: var(--fs-md-h);
          line-height: var(--lh-md-h);
          margin-bottom: var(--dist-2);
          text-wrap: balance;
        }

        .CardArtist[data-size="list"] .CardArtistName,
        .CardArtistInfo {
          font-size: var(--fs-xs);
          line-height: var(--lh-xs);
          font-family: "ABCRomMono-Regular";
        }
      `}</style>
    </>
  );
};

export default CardArtist;
