import { AnimatePresence, motion } from "framer-motion";
import useMeasure from "react-use-measure";

import Content from "../content/Content";
import Media from "./Media";

const BannerSlide = ({ media, active }) => {
  const [ref, bounds] = useMeasure();

  return (
    <>
      <div
        className="BannerSlide"
        style={{ "--h-slide": bounds?.height + "px" }}
      >
        <Media media={media} />
        {media?.content && (
          <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{
              opacity: active ? 1 : 0,
              transition: { delay: active ? 0.5 : 0 },
            }}
            exit={{ opacity: 0, transition: { delay: 0 } }}
            className="caption"
            style={{
              "--ratio":
                media?.image?.asset?.metadata?.dimensions?.aspectRatio ||
                media?.video?.asset?.data?.aspect_ratio ||
                1,
            }}
          >
            <Content blocks={media?.content} />
          </motion.div>
        )}
      </div>

      <style jsx global>
        {""}
      </style>
    </>
  );
};

export default BannerSlide;
