import { isEmpty } from "lodash";

const BlockList = ({ as: List, type, children }) => {
  return isEmpty(children) ? null : (
    <>
      <List className="BlockList" data-type={type}>
        {children}
      </List>

      <style jsx global>{`
        .BlockList {
          padding: 0 var(--dist-1);
          font-size: var(--fs-sm);
          line-height: var(--lh-sm);
          font-family: "Eliza-Regular";
        }

        @media (min-width: 1024px) {
          .Content[data-full="true"] .BlockList {
            padding-left: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
          }
        }

        ul.BlockList {
          padding-left: 2.9ch;
        }

        ul.BlockList li::before {
          content: "•";
          width: 2.9ch;
          margin-left: -2.9ch;
          display: inline-block;
          padding-left: 0.75ch;
        }

        ol.BlockList {
          padding-left: 2.9ch;
          counter-reset: num;
        }

        ol.BlockList li::before {
          counter-increment: num;
          content: counter(num) ". ";
          width: 2.9ch;
          margin-left: -2.9ch;
          display: inline-block;
        }
      `}</style>
    </>
  );
};

export default BlockList;
