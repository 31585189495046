import Content from "@/components/content/Content";

const BlockTextColumns = ({ value }) => {
  const { left, right } = value || [];
  return (
    <>
      <div className="Block BlockTextColumns">
        <div className="BlockTextColumnsColumn">
          <Content blocks={left ?? []} />
        </div>
        <div className="BlockTextColumnsColumn">
          <Content blocks={right ?? []} />
        </div>
      </div>

      <style jsx global>{`
        .BlockTextColumns {
          display: grid !important;
          grid-template-columns: repeat(1, minmax(0, 1fr));
          gap: var(--global-gutter);
          grid-row-gap: calc(var(--dist-3));
          padding-left: calc(var(--dist-1));
          padding-right: calc(var(--dist-1));
        }

        .BlockTextColumns
          .BlockText[data-type="normal"]
          + .BlockText[data-type="normal"] {
          padding-top: calc(var(--dist-3));
        }

        .BlockTextColumns
          .BlockText[data-type="normal"]
          + .BlockText[data-type="small"] {
          padding-top: calc(var(--dist-3));
        }
        .BlockTextColumns
          .BlockText[data-type="small"]
          + .BlockText[data-type="normal"] {
          padding-top: calc(var(--dist-1));
        }
        .BlockTextColumns .BlockText[data-type="normal"] {
          font-size: var(--fs-sm);
          line-height: var(--lh-sm);
          font-family: "Eliza-Regular";
        }

        @media (min-width: 1024px) {
          .BlockTextColumns {
            padding-left: calc(
              2 * (var(--global-gutter) + var(--global-column)) + var(--dist-1)
            );
            padding-right: calc(
              2 * var(--global-column) + 2 * var(--global-gutter) +
                var(--dist-1)
            );
            display: grid !important;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
      `}</style>
    </>
  );
};

export default BlockTextColumns;
